import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ModalBody, ModalFooter } from 'react-bootstrap'
import { recepientStore } from '../store/recepientStore'
import { toast } from 'react-toastify'
import { queueSchemeStore } from '../store/queueSchemeStore'

export const AppModal = () => {
	const [appModalOpen, setAppModalOpen] = useState(false)
	const [callingRecepient, setCallingRecepient] = useState()
	const [cancelModal, setCancelModal] = useState(false)
	const [skipModal, setSkipModal] = useState(false)

	const { recepients, finishRecepient, skipRecepient } =
		recepientStore()

	const { skipRecepient: qSkipRecepient } = queueSchemeStore()

	const goBackOrCancelFc = () => {
		if (cancelModal || skipModal) {
			setCancelModal(false)
			setSkipModal(false)
		} else {
			setCancelModal(true)
		}
	}

	const submitOrSkipFc = () => {
		if (skipModal) {
			skipRecepientFc()
		} else if (cancelModal) {
			exitFunction()
		} else {
			setSkipModal(true)
		}
	}

	const exitFunction = async () => {
		try {
			await finishRecepient(
				callingRecepient.id,
				callingRecepient.queues[0].id,
				callingRecepient.queues[0].recepients[0].id
			)
			setAppModalOpen(false)

			console.log(recepients)
		} catch (e) {
			toast.error(e.response.data.message)
		}
	}

	const skipRecepientFc = async () => {
		const [number, status] = await skipRecepient(
			callingRecepient.id,
			callingRecepient.queues[0].id,
			callingRecepient.queues[0].recepients[0].id
		)
		await qSkipRecepient(
			callingRecepient.id,
			callingRecepient.queues[0].recepients[0].id,
			number,
			status
		)
		setCallingRecepient(false)
	}

	useEffect(() => {
		const recepient = recepients.find(
			(q) => q.queues[0].recepients[0].status === 'calling'
		)

		if (recepient) {
			setAppModalOpen(true)
			setCallingRecepient(recepient)
		}
	}, [recepients])

	if (!callingRecepient) return <></>

	return (
		<Modal show={appModalOpen} centered>
			<ModalBody>
				{skipModal
					? 'Do you want to skip?'
					: cancelModal
						? 'Do you want to cancel yout queue'
						: 'Your turn now'}
			</ModalBody>
			<ModalFooter>
				<button onClick={() => goBackOrCancelFc()}>
					{skipModal || cancelModal ? 'Go Back' : 'Cancel'}
				</button>
				<button onClick={() => submitOrSkipFc()}>
					{skipModal
						? 'skip'
						: cancelModal
							? 'cancel'
							: 'Skip'}
				</button>
			</ModalFooter>
		</Modal>
	)
}
