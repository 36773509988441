import { create } from 'zustand'
import { getLanguage } from '../http/languageApi'

export const languageStore = create((set) => ({
	language: [],
	getLanguage: async () => {
		const language = await getLanguage()
		set({ language })
	}
}))
