import axios from 'axios'
require('dotenv').config()

const $host = axios.create({
	baseURL: process.env.REACT_APP_API_URL
})

const $authHost = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: {
		'Accept-Navbat-Language': localStorage.getItem('lang')
	}
})

const authInterceptor = (config) => {
	// config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
	return config
}

$authHost.interceptors.request.use(authInterceptor)

export { $host, $authHost }
