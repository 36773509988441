import React, { useState } from 'react'
import { languageStore } from '../store/languageStore'
import { useTranslation } from 'react-i18next'
import { authStore } from '../store/authStore'
import { useNavigate } from 'react-router-dom'

export const ChangeLanguage = () => {
	const { t, i18n } = useTranslation()
	const { language } = languageStore()
	const { user, changeLang } = authStore()
	const navigate = useNavigate()

	const [changeLangBT, setChangeLangBT] = useState(
		language.find((lang) => lang.id === user.langId)
	)

	const changeLanguageFc = async () => {
		if (changeLangBT.id !== user.langId) {
			await changeLang(changeLangBT.id)
			await i18n.changeLanguage(changeLangBT.shortName)
		}
		navigate(-1)
	}

	return (
		<>
			<header className="QCHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => window.history.back()}></i>
				<h2 className="mainTopTitle flex-grow-1">
					{t('ChangeLanguage')}
				</h2>
			</header>
			<main className="QCMain pt-0">
				{language.map((lang) => (
					<label className="w-100 cabinetRow dark flex-row">
						<button
							onClick={() => setChangeLangBT(lang)}
							style={{
								background: 'transparent',
								border: 0,
								fontSize: 20,
								display: 'flex',
								alignItems: 'center',
								gap: 15
							}}
							disabled={user?.langId === lang.id}>
							<img
								src={lang.flag}
								srcSet={lang.flag}
								alt={'Flag of ' + lang.name}
								height={30}
							/>
							<span>{lang.name}</span>
						</button>

						{changeLangBT?.id === lang.id && (
							<i
								className="las la-check"
								style={{ fontSize: 24 }}></i>
						)}
					</label>
				))}

				<button
					style={{}}
					className="btnSubmit"
					submit=""
					onClick={changeLanguageFc}>
					{t('Submit')}
				</button>
			</main>
		</>
	)
}
