import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { authStore } from '../store/authStore'

const schema = yup.object({
	username: yup.string().required('Username is required field')
})

export const ChangeUserNameModal = ({
	handleUName,
	setHandleUName
}) => {
	const { user, changeUserName, checkUsername } = authStore()
	const [userName, setUserName] = useState(user.username)
	const [message, setMessage] = useState('')
	const [isNotCorrectUsername, setIsNotCorrectUsername] =
		useState(true)

	const onCancelFc = () => {
		setHandleUName(false)
		setUserName(user.username)
		setMessage('')
	}

	const changeUserNameSubmitFc = async (e) => {
		if (user.username === userName) return setHandleUName(false)
		await handleSubmit((data) => {
			changeUserName(data)
			setHandleUName(false)
		})(e)
	}

	const handleUsernameChange = (e) => {
		setIsNotCorrectUsername(true)
		setUserName(e.target.value.toLowerCase())
	}

	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema)
	})

	useEffect(() => {
		const delay = setTimeout(async () => {
			if (userName.length) {
				if (user.username === userName) {
					setIsNotCorrectUsername(false)
					setMessage('')
					return
				}
				const { message } = await checkUsername(userName)

				if (message === 'Available') {
					setIsNotCorrectUsername(false)
					setMessage('This username is available!')
				} else if (message === 'NotAvailable') {
					setMessage('This username is already occupied')
				} else if (message === 'NotValid') {
					setMessage(
						'Only a-z, 0-9, and underscores allowed'
					)
				} else if (message === 'InvalidLength') {
					setMessage('This username is invalid')
				}
			}
		}, 1500)
		return () => clearTimeout(delay)
	}, [userName, user.username, checkUsername])

	return (
		<Modal
			open={handleUName}
			onCancel={() => setHandleUName(false)}
			centered
			footer={
				<div className="cabinetModalBt">
					<button submit="" onClick={onCancelFc}>
						Cancel
					</button>
					<button
						submit=""
						disabled={isNotCorrectUsername}
						type="submit"
						onClick={changeUserNameSubmitFc}>
						OK
					</button>
				</div>
			}>
			<div className="cabinetModals">
				<div className="cabinetTextInput">
					<p>User Name</p>
					<input
						{...register('username')}
						onChange={handleUsernameChange}
						value={userName}
					/>
					<span
						className={
							message?.endsWith('!')
								? 'cabinetNamesSuccess'
								: 'cabinetNamesError'
						}>
						{errors.username?.message || message}
					</span>
				</div>
			</div>
		</Modal>
	)
}
