import moment from 'moment'
import React, {
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { recepientStore } from '../store/recepientStore'

const HistoryEmployed = ({ ...props }) => {
	const { t } = useTranslation()

	const [clientQueues, setClientQueues] = useState([])
	const [loading, setLoading] = useState(false)
	const { getRecepientHistory } = recepientStore()

	const [page, setPage] = useState(1)
	const [hasMore, setHasMore] = useState(true)

	const observer = useRef(null)

	const lastElementRef = useRef(null)

	const fetchMoreData = useCallback(async () => {
		const recepientHistory = await getRecepientHistory(page)
		if (recepientHistory.length === 0) {
			setHasMore(false)
		} else {
			setClientQueues((prevQueues) => [
				...prevQueues,
				...recepientHistory
			])
		}
		setLoading(false)
	}, [getRecepientHistory, page])

	useEffect(() => {
		fetchMoreData()
	}, [page, fetchMoreData])

	useEffect(() => {
		if (observer.current) observer.current.disconnect()

		observer.current = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setPage((prevPage) => prevPage + 1)
				}
			},
			{
				root: null,
				rootMargin: '100px',
				threshold: 0.5
			}
		)

		if (lastElementRef.current) {
			observer.current.observe(lastElementRef.current)
		}
	}, [hasMore, clientQueues])

	const classIcon = (queue) => {
		if (queue.status === 'active') {
			return ['iconGreen', 'arrow-circle-right', 'На приеме']
		} else if (queue.status === 'calling') {
			return ['iconGreen', 'running', 'Ваша очередь']
		} else if (queue.status === 'pending') {
			return ['iconYellow', 'stopwatch', 'В ожидании']
		} else if (queue.status === 'done') {
			return ['iconGray', 'check-circle', 'Завершено']
		} else if (queue.status === 'missed') {
			return ['iconOrange', 'arrow-circle-left', 'Пропущенно']
		} else if (queue.status === 'cancelled') {
			return ['iconRed', 'times-circle', 'Отменено']
		} else if (queue.status === 'skipping') {
			return ['iconOrange', 'pause-circle', 'Пропуск']
		} else {
			return ['iconRed', 'exclamation-circle', 'Неизвестно']
		}
	}

	if (loading && page === 1) {
		return (
			<center>
				<h2>
					L<Spinner animation={'border'} />
					ADING
				</h2>
			</center>
		)
	}

	return (
		<>
			{clientQueues.length ? (
				<section {...props}>
					{clientQueues.map((queue, index) => (
						<div
							className="hisGot"
							key={queue.id}
							ref={
								clientQueues.length === index + 1
									? lastElementRef
									: null
							}>
							<div
								className={
									'hisQueue ' + classIcon(queue)[0]
								}>
								<i
									className={
										'la la-' +
										classIcon(queue)[1] +
										' la-2x'
									}></i>
								<div className="ml-2">
									{classIcon(queue)[2]}
								</div>
							</div>
							<div className="hisQueue">
								<div className="flex-grow-1">
									<h5 className="queueName">
										{queue.name}
									</h5>
									<p className="queueTime">
										{t('AverageInterval')}: 0{' '}
										{t('Minute')}
									</p>
								</div>
								{[
									'pending',
									'calling',
									'active'
								].includes(queue.status) ? (
									<div className="queueNum">
										<p>{t('Your')} №</p>
										<p>{queue.number}</p>
									</div>
								) : null}
							</div>
							<div className="hisQueue">
								<i className="la la-calendar-check la-2x cGray"></i>
								<div className="ml-2">
									<p className="queueCrTimeText">
										{t('inLineWith')}
									</p>
									<p className="queueCrTime">
										{moment(
											queue.createdAt
										).format('DD.MM.YYYY HH:mm')}
									</p>
								</div>
							</div>
						</div>
					))}
				</section>
			) : (
				<article className="emptyHome">
					<h3 className="emptyHomeHeader">
						{t('NoQueuesCreatedYet')}
					</h3>
					<p className="emptyHomeText">
						{t('AddNewQueueUseButton')}
					</p>
				</article>
			)}
		</>
	)
}

export default HistoryEmployed
