import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
	debug: true,
	interpolation: {
		escapeValue: false
	},
	resources: {
		uz: {
			translation: {
				YourQueues: 'Sizning navbatlaringiz',
				Dear: 'Hurmatli',
				YouHaveNotQueue: "Sizda hali navbatlar yo'q",
				ProvideCodeOrQR:
					"Kod orqali navbat qo'shing yoki QR-ni skanerlang",
				EnterCode: 'Kodni kiriting',
				Home: 'Asosiy',
				Queue: 'Navbat',
				Monitoring: 'Monitoring',
				Profile: 'Shaxsiy',
				ToJoinQueue: 'Navbatga kirish uchun',
				UseCodeOrQR: 'kod yoki QR skanerdan foydalaning',
				Understand: 'Hammasi tushunarli!',
				Queues: 'Navbatlar',
				ListOfQueues: "Navbatlar ro'yxati",
				CreatedData: "Qo'shilgan sana",
				CurrentNumber: 'Joriy raqam №',
				Create: "Qo'shish",
				CreateQueue: "Navbat qo'shing",
				QueueName: 'Navbat nomi',
				Description: 'Tavsif',
				MonFri: 'Dush-Jum',
				MonSat: 'Dush-Shan',
				Everday: 'Har kuni',
				Custimize: 'Moslashtirish',
				Start: 'Boshlash',
				End: 'Oxiri',
				Next: 'Keyingisi',
				Services: 'Xizmatlar',
				NoServices: "Xizmatlar yo'q",
				AddService: "Xizmat qo'shish",
				СreateService: "Xizmatni qo'shish",
				Duration: 'Davomiyligi',
				Fee: "To'lov",
				min: 'min',
				sum: "so'm",
				isAgreed: 'Kelishilgan',
				Save: 'Saqlash',
				History: 'Tarix',
				Taken: 'Band qilingan',
				Created: "Qo'shilgan",
				AverageInterval: "O'rtacha interval",
				Minute: 'minut',
				Your: 'Sizning',
				InLineWith: 'Siz bir qatordasiz',
				Code: 'Kod',
				NoQueuesCreatedYet:
					"Sizda hozircha hech qanday navbat qo'shilmagan",
				AddNewQueueUseButton:
					"Tugma yordamida yangi navbat qo'shing",
				Username: 'Foydalanuvchi ismi',
				PhoneNumber: 'Telefon raqam',
				Tariff: 'Tarif',
				ChangeTariff: "Tarifni o'zgartirish",
				Exit: 'Chiqish',
				Share: 'Ulashish',
				NeverShareNumber:
					'Biz sizning raqamingizni hech kimga bermaymiz.',
				FirstName: 'Ismingiz',
				MiddleName: 'Otasingizni ismi',
				LastName: 'Familiyangiz',
				ConfirmPassword: 'Parolni tasdiqlang',
				NeverShareEmail:
					'Biz sizning emailingizni hech kimga bermaymiz.',
				Password: 'Parol',
				Language: 'Til',
				ChangeLanguage: "Tilni o'zgartirish",
				Back: 'Orqaga',
				Submit: 'Saqlash',
				MonitoringQueues: 'Navbatlar manitoringi',
				UserInfo: 'F.I.Sh',
			}
		},
		ru: {
			translation: {
				YourQueues: 'Ваши очереди',
				Dear: 'Уважаемый',
				YouHaveNotQueue: 'У вас пока нет записей в очереди',
				ProvideCodeOrQR:
					'Добавьте очередь через код или отсканируйте QR',
				EnterCode: 'Ввести код',
				Home: 'Главная',
				Queue: 'Очередь',
				Monitoring: 'Мониторинг',
				Profile: 'Профиль',
				ToJoinQueue: 'Чтобы занять очередь',
				UseCodeOrQR: 'используйте код или QR сканер',
				Understand: 'Все понятно!',
				Queues: 'Oчереди',
				ListOfQueues: 'Список очередей',
				CreatedData: 'Дата создания',
				CurrentNumber: 'Текущий №',
				Create: 'Создать',
				CreateQueue: 'Создать очередь',
				QueueName: 'Название очереди',
				Description: 'Описание',
				MonFri: 'Пн-Пт',
				MonSat: 'Пн-Сб',
				Everday: 'Каждый день',
				Custimize: 'Настроить',
				Start: 'Начать',
				End: 'Kонец',
				Next: 'Следующий',
				Services: 'Услуги',
				NoServices: 'Hет услуг',
				AddService: 'Добавить услугу',
				СreateService: 'Создать сервис',
				Duration: 'Продолжительность',
				Fee: 'Платеж',
				min: 'мин',
				sum: 'сум',
				isAgreed: 'Согласен',
				Save: 'Сохранить',
				History: 'История',
				Taken: 'Занятые',
				Created: 'Созданный',
				AverageInterval: 'Средний интервал',
				Minute: 'минут',
				Your: 'Ваш',
				InLineWith: 'Вы в одной очереди с',
				Code: 'Код',
				NoQueuesCreatedYet:
					'У вас еще нет созданных очередей',
				AddNewQueueUseButton:
					'Добавте новую очередь с помощью кнопки',
				Username: 'Имя пользователя',
				PhoneNumber: 'Номер телефона',
				Tariff: 'Тариф',
				ChangeTariff: 'Cменить тариф',
				Exit: 'Выйти',
				Share: 'Поделиться',
				NeverShareNumber:
					'Мы никогда не передадим ваш номер кому-либо еще.',
				FirstName: 'Имя',
				MiddleName: 'Отчество',
				LastName: 'Фамилия',
				ConfirmPassword: 'Подтвердите пароль',
				NeverShareEmail:
					'Мы никогда не будем передавать ваш email кому-либо еще.',
				Password: 'Пароль',
				Language: 'Язык',
				ChangeLanguage: 'Изменить язык',
				Back: 'Назад',
				Submit: 'Подтвердить',
				MonitoringQueues: 'Мониторинг очередей',
				UserInfo: 'Ф. И. О.',
			}
		},
		en: {
			translation: {
				YourQueues: 'your Queues',
				Dear: 'Dear',
				YouHaveNotQueue: 'You have not queues yet',
				ProvideCodeOrQR:
					'Add a queue via code or scan the QR',
				EnterCode: 'Enter the code',
				Home: 'Home',
				Queue: 'Queue',
				Monitoring: 'Monitoring',
				Profile: 'Profile',
				ToJoinQueue: 'To join a queue',
				UseCodeOrQR: 'use code or QR scanner',
				Understand: 'I understand!',
				Queues: 'Queues',
				ListOfQueues: 'List of queues',
				CreatedData: 'Date of creation',
				CurrentNumber: 'Current №',
				Create: 'Create',
				CreateQueue: 'Create queue',
				QueueName: 'Queue name',
				Description: 'Description',
				MonFri: 'Mon-Fri',
				MonSat: 'Mon-Sat',
				Everday: 'Everyday',
				Custimize: 'Customise',
				Start: 'Start',
				End: 'End',
				Next: 'Next',
				Sevices: 'Sevices',
				NoServices: 'No Services',
				AddService: 'Add service',
				СreateService: 'Create service',
				Duration: 'Duration',
				Fee: 'Fee',
				min: 'min',
				sum: 'sum',
				isAgreed: 'is agreed',
				Save: 'Save',
				History: 'History',
				Taken: 'Taken',
				Created: 'Created',
				AverageInterval: 'Average interval',
				Minute: 'minute',
				Your: 'Your',
				InLineWith: 'You are in line with',
				Code: 'Code',
				NoQueuesCreatedYet:
					'You have not created any queues yet',
				AddNewQueueUseButton:
					'Add new queue using the button',

				Username: 'Username',
				PhoneNumber: 'Phone number',
				Tariff: 'Tariff',
				ChangeTariff: 'Change tariff',
				Exit: 'Exit',
				Share: 'Share',
				NeverShareNumber:
					"We'll never share your number with anyone else.",
				FirstName: 'First Name',
				MiddleName: 'Middle Name',
				LastName: 'Last Name',
				ConfirmPassword: 'Confirm Password',
				NeverShareEmail:
					"We'll never share your email with anyone else.",
				Password: 'Password',
				Language: 'Language',
				ChangeLanguage: 'Change language',
				Back: 'Back',
				Submit: 'Submit',
				MonitoringQueues: 'Monitoring queues',
				UserInfo: 'Full name',
			}
		}
	}
})

export default i18n
