import React from 'react'
import arrowLeft from '../assets/arrowLeftTip.svg'
import arrowRight from '../assets/arrowRightTip.svg'
import { useNavigate } from 'react-router-dom'
import { HOME_ROUTE } from '../util/routenames'
import { useTranslation } from 'react-i18next'

const Tip = ({ onAction }) => {
	const navigate = useNavigate()
	const closeWindow = () => {
		onAction()
		return navigate(HOME_ROUTE)
	}

	const { t } = useTranslation()
	return (
		<div tipforhome="">
			<p className="d-flex justify-content-between mx-2">
				<img src={arrowLeft} alt="123" />
				<img src={arrowRight} alt="123" />
			</p>
			<p className="d-flex justify-content-center mx-5 text-center">
				{t('ToJoinQueue')}
				<br />
				{t('UseCodeOrQR')}
			</p>
			<p className="QANew btnSubmit pl-3 pr-3">
				<button
					className="QANewBtn m-auto w-auto"
					submit=""
					onClick={closeWindow}>
					<i className="las la-thumbs-up la-2x"></i>
					{t('Understand')}
				</button>
			</p>
		</div>
	)
}

export default Tip
