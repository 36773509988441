import { create } from 'zustand'
import { getQueueCategory } from '../http/queueCategoryApi'

export const queueCategoryStore = create((set) => ({
	queueCategory: [],
	getQueueCategory: async () => {
		const queueCategory = await getQueueCategory()
		set({ queueCategory })
	}
}))
