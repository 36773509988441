import { create } from 'zustand'
import {
	activate,
	checkQueueScheme,
	createQueueScheme,
	getOneQueueSchemeFromClient,
	getQueueSchemes
} from '../http/queueSchemeApi'

export const queueSchemeStore = create((set) => ({
	queueScheme: [],
	joiningQueueSchemes: [],
	createQueueScheme: async (queueScheme) => {
		const data = await createQueueScheme(queueScheme)
		set((state) => {
			return {
				...state,
				queueScheme: [...state.queueScheme, data]
			}
		})
		return { id: data.id }
	},
	getQueueSchemes: async () => {
		const queueScheme = await getQueueSchemes()

		set(() => ({ queueScheme }))
	},
	activate: async (queueSchemeId, startDate) => {
		await activate(queueSchemeId, startDate)

		queueSchemeStore
			.getState()
			.queueScheme.find(
				(qs) => qs.id === queueSchemeId
			).isJoinable = true

		// TODO: FINISH THERE!!!
	},
	checkQueueScheme: async (unique) => {
		const data = await checkQueueScheme(unique)
		return data
	},
	getOneQueueSchemeFromClient: async (queueSchemeId) => {
		const cachedQS = queueSchemeStore
			.getState()
			.joiningQueueSchemes.find((qs) => qs.id === queueSchemeId)
		if (cachedQS) return cachedQS

		const queueScheme =
			await getOneQueueSchemeFromClient(queueSchemeId)

		set((state) => {
			return {
				...state,
				joiningQueueSchemes: [
					...state.joiningQueueSchemes,
					queueScheme
				],
				queueScheme: [...state.queueScheme, queueScheme]
			}
		})

		return queueScheme
	},
	skipRecepient: (queueSchemeId, recepientId, number, status) => {
		set((state) => {
			return {
				...state,
				queueScheme: state.queueScheme.map((qs) => {
					if (queueSchemeId === qs.id) {
						return {
							...qs,
							queues: qs.queues.flatMap((q) => ({
								...q,
								recepients: q.recepients.map((rr) => {
									if (rr.id === recepientId) {
										return {
											...rr,
											status,
											...(number
												? { number }
												: {})
										}
									}
									return rr
								})
							}))
						}
					}
					return qs
				})
			}
		})
	},
	returnRecepient: (queueSchemeId, recepientId) => {
		set((state) => {
			return {
				...state,
				queueScheme: state.queueScheme.map((qs) => {
					if (queueSchemeId === qs.id) {
						return {
							...qs,
							queues: qs.queues.flatMap((q) => ({
								...q,
								recepients: q.recepients.map((rr) => {
									if (rr.id === recepientId) {
										return {
											status: 'pending'
										}
									}
									return rr
								})
							}))
						}
					}
					return qs
				})
			}
		})
	}
}))
