import React from 'react'
import { clientRoutes, publicRoutes } from '../routes'
import { Route, Routes } from 'react-router-dom'

export const AppRouter = (data) => {
	return (
		<Routes>
			{[...publicRoutes, ...clientRoutes].map(
				(route, index) => {
					if (route === '*')
						return (
							<Route
								path="*"
								key={404}
								element={<div>Page not found</div>}
							/>
						)
					return (
						<Route
							key={index}
							path={route.path}
							element={<route.Element {...data} />}
						/>
					)
				}
			)}
		</Routes>
	)
}
