import { create } from 'zustand'
import {
	finishRecepient,
	getRecepientHistory,
	// getRecepients,
	getRecepientsSchemes,
	join,
	skipRecepient
} from '../http/recepientApi'
import { queueSchemeStore } from './queueSchemeStore'

export const recepientStore = create((set) => ({
	recepients: [],
	recepientHistory: [],
	isRecepientsFetched: false,

	// getRecepients: async () => {
	// 	const recepients = recepientStore.getState().recepient

	// 	if (
	// 		recepients.length ||
	// 		recepientStore.getState().isRecepientsFetched
	// 	)
	// 		return recepients

	// 	const recepient = await getRecepients()
	// 	set({ recepient, isRecepientsFetched: true })
	// },
	getRecepientsSchemes: async () => {
		const recepient = recepientStore.getState().recepients

		if (
			recepient.length ||
			recepientStore.getState().isRecepientsFetcheds
		)
			return recepient

		const recepients = await getRecepientsSchemes()
		set({ recepients, isRecepientsFetched: true })
	},
	join: async (data) => {
		const recepient = await join(data)

		const qs = queueSchemeStore
			.getState()
			.queueScheme.find(
				(qsss) => qsss.id === recepient.queueSchemeId
			)

		const resRecepient = { ...qs }
		resRecepient.queues[0].recepients = [recepient]

		set((state) => {
			console.log({
				...state,
				recepients: [...state.recepients, resRecepient]
			})
			return {
				...state,
				recepients: [...state.recepients, resRecepient]
			}
		})
		return recepient
	},
	getRecepientHistory: async (page) => {
		const history = await getRecepientHistory(page)
		return history
	},
	finishRecepient: async (queueSchemeId, queueId, recepientId) => {
		await finishRecepient(queueSchemeId, queueId, recepientId)
		set((state) => {
			return {
				...state,
				recepients: state.recepients.filter(
					(recepient) =>
						recepient.id !== queueSchemeId &&
						recepient.queues[0].id !== queueId &&
						recepient.queues[0].recepients[0].id !==
							recepientId
				)
			}
		})
	},

	skipRecepient: async (
		queueSchemeId,
		queueId,
		recepientId,
		number
	) => {
		const data = await skipRecepient(
			queueSchemeId,
			queueId,
			recepientId
		)

		set((state) => {
			return {
				...state,
				recepients: state.recepients.map((qs) => {
					if (queueSchemeId === qs.id) {
						return {
							...qs,
							queues: qs.queues.map((q) => ({
								...q,
								recepients: q.recepients.map((r) => {
									if (r.id === recepientId) {
										return {
											...r,
											...(data.status
												? {
														status: data.status
													}
												: data.number
													? {
															status: 'pending',
															number: data.number
														}
													: {})
										}
									}
									return r
								})
							}))
						}
					}
					return qs
				})
			}
		})
		return [data.number ? data.number : number, data.status]
	},

	returnRecepient: async (queueSchemeId, queueId, recepientId) => {
		// const { message } = await returnRecepient(
		// 	queueSchemeId,
		// 	queueId,
		// 	recepientId
		// )

		// if (message === '') {
		// 	// FIX THIS
		// 	return
		// }

		set((state) => {
			console.log({
				...state,
				recepients: state.recepients.map((qs) => {
					if (queueSchemeId === qs.id) {
						return {
							...qs,
							queues: qs.queues.map((q) => ({
								...q,
								recepients: q.recepients.map((r) => {
									if (r.id === recepientId) {
										return {
											...r,
											status: 'pending'
										}
									}
									return r
								})
							}))
						}
					}
					return qs
				})
			})
			return {
				...state,
				recepients: state.recepients.map((qs) => {
					if (queueSchemeId === qs.id) {
						return {
							...qs,
							queues: qs.queues.map((q) => ({
								...q,
								recepients: q.recepients.map((r) => {
									if (r.id === recepientId) {
										return {
											...r,
											status: 'pending'
										}
									}
									return r
								})
							}))
						}
					}
					return qs
				})
			}
		})
	}
}))
