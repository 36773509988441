import {
	ABOUT_ROUTE,
	HISTORY_ROUTE,
	HOME_ROUTE,
	PROFILE_ROUTE,
	QUEUE_SCHEME_ROUTE,
	RESULT_ROUTE,
	START_ROUTE,
	// CREATE_QUEUE_SCHEME_ROUTE,
	// CREATE_SERVICE_ROUTE,
	// SERVICE_LIST_ROUTE,
	// QUEUE_START_ROUTE,
	EDIT_QUEUE_SCHEME_ROUTE,
	CHANGE_LANGUAGE_ROUTE,
	OBSERVE_ROUTE,
	ALL_SERVICES_ROUTE
} from './util/routenames'
import { About } from './pages/About'
import { Home } from './pages/Home'
import { Welcome } from './pages/Welcome'
import { Result } from './pages/Result'
import { Profile } from './pages/Profile'
import { History } from './pages/History'
// import { QueueSchemes } from './pages/QueueSchemes'
import { QueueCreate } from './pages/QueueCreate'
import { QueueOne } from './pages/QueueOne'
// import { CreateService } from './pages/CreateService'
// import { ServiceList } from './pages/ServiceList'
// import { QueueStart } from './pages/QueueStart'
import { ChangeLanguage } from './pages/ChangeLanguage'
import { ObserveQueue } from './pages/Observe'
import { Services } from './pages/Services'
import { ServiceOne } from './pages/ServiceOne'

export const publicRoutes = [
	{
		path: ABOUT_ROUTE,
		Element: About
	}
]
export const clientRoutes = [
	{
		path: HOME_ROUTE,
		Element: Home
	},
	{
		path: START_ROUTE,
		Element: Welcome
	},
	{
		path: RESULT_ROUTE + '/:id',
		Element: Result
	},
	{
		path: PROFILE_ROUTE,
		Element: Profile
	},
	{
		path: HISTORY_ROUTE,
		Element: History
	},
	// {
	// 	path: QUEUE_SCHEME_ROUTE,
	// 	Element: QueueSchemes
	// },
	// {
	// 	path: CREATE_QUEUE_SCHEME_ROUTE,
	// 	Element: QueueCreate
	// },
	{
		path: EDIT_QUEUE_SCHEME_ROUTE + '/:id',
		Element: QueueCreate
	},
	{
		path: QUEUE_SCHEME_ROUTE + '/:id',
		Element: QueueOne
	},
	// {
	// 	path: QUEUE_SCHEME_ROUTE + '/:id' + CREATE_SERVICE_ROUTE,
	// 	Element: CreateService
	// },
	// {
	// 	path: QUEUE_SCHEME_ROUTE + '/:id' + SERVICE_LIST_ROUTE,
	// 	Element: ServiceList
	// },
	// {
	// 	path: QUEUE_SCHEME_ROUTE + '/:id' + QUEUE_START_ROUTE,
	// 	Element: QueueStart
	// },
	{
		path: CHANGE_LANGUAGE_ROUTE,
		Element: ChangeLanguage
	},
	{
		path: OBSERVE_ROUTE + '/:id',
		Element: ObserveQueue
	},
	{
		path: OBSERVE_ROUTE + ALL_SERVICES_ROUTE + '/:id',
		Element: Services
	},
	{
		path: OBSERVE_ROUTE + '/:id/:serviceId',
		Element: ServiceOne

	}
]
