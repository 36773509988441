import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
	HOME_ROUTE,
	OBSERVE_SERVICES_ROUTE
} from '../util/routenames'
import './css/Observing.css'
import { queueSchemeStore } from '../store/queueSchemeStore'
import { toDataURL } from 'qrcode'
import { authStore } from '../store/authStore'
import { useTranslation } from 'react-i18next'
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter
} from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import { recepientStore } from '../store/recepientStore'

export const ObserveQueue = () => {
	const { id } = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [queue, setQueue] = useState()
	const [qrImg, setQrImg] = useState()
	const [handleModal, setHandleModal] = useState(false)
	const [step, setStep] = useState(1)
	const [prevNum, setPrevNum] = useState()

	const {
		getOneQueueSchemeFromClient,
		skipRecepient: qSkipRecepient,
		queueScheme,
		returnRecepient: qReturnRecepient
	} = queueSchemeStore()
	const { skipRecepient, returnRecepient } = recepientStore()
	const { user } = authStore()

	useEffect(() => {
		const fetchData = async () => {
			const qqq = queueScheme.find((q) => q.id === id)
			if (!qqq)
				return setQueue(await getOneQueueSchemeFromClient(id))
			setQueue(qqq)
		}
		fetchData()
	}, [getOneQueueSchemeFromClient, id, queueScheme])

	useEffect(() => {
		if (!queue) return
		const url =
			process.env.REACT_APP_SITE_URL + 'r/' + queue.unique
		toDataURL(url, {
			width: 150,
			margin: 0,
			color: { light: '#00000000' }
		})
			.then((data) => {
				const img = React.createElement('img', {
					src: data,
					alt:
						process.env.REACT_APP_SITE_URL +
						'r/' +
						queue.unique +
						'?ref=' +
						user.nvbtId
				})
				setQrImg(img)
			})
			.catch((e) => console.error(e))
	}, [id, queue, user.nvbtId])

	const currentWorkday = queue
		? queue.workdays.find(
				(wd) => wd.workday === new Date().getDay()
			)
		: null

	const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

	const closeModalFc = () => {
		setHandleModal(false)
		setStep(0)
	}

	const buttonSkip = () => {
		setHandleModal(true)
		setStep(1)
		setPrevNum(queue.queues[0].recepients[0]?.number)
	}

	const buttonReturn = () => {
		setHandleModal(true)
		setStep(3)
	}

	const skipRecepientFc = () => {
		skipRecepient(
			queue.id,
			queue.queues[0].id,
			queue.queues[0].recepients[0].id,
			queue.queues[0].recepients[0].number
		).then(([number, status]) => {
			qSkipRecepient(
				queue.id,
				queue.queues[0].recepients[0].id,
				number,
				status
			)
		})
		setStep(step + 1)
	}

	const returnRecepientFc = async () => {
		await returnRecepient(
			queue.id,
			queue.queues[0].id,
			queue.queues[0].recepients[0].id
		)
		qReturnRecepient(queue.id, queue.queues[0].recepients[0].id)
		setStep(step + 1)
	}

	if (!queue) return <></>


	return (
		<>
			<header className="QCHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => navigate(HOME_ROUTE)}></i>
				<h2 className="mainTopTitle">Наблюдение</h2>
				<span></span>
			</header>
			<main className="result">
				<h2>{queue.name}</h2>
				<p className="cGreen pl-0 pt-3">
					Средний интервал:{' '}
					{queue.activeWorkTime
						? (
								queue.activeWorkTime.reduce(
									(a, b, _ar, arr) =>
										(a + b) / arr.length
								) / 1000
							).toFixed(2) + ' мин.(сек)'
						: 'нет данных'}
				</p>
				<Link
					to={OBSERVE_SERVICES_ROUTE + '/' + id}
					className="align-items-center cabinetRow">
					<i className="la la-ambulance la-2x"></i>
					<div className="pl-3 flex-grow-1 dark">
						<div>{t('Services')}</div>
					</div>
					<i className="la la-1x la-angle-right"></i>
				</Link>
				<div className="cabinetRow border-0">
					<i className="la la-clock la-2x"></i>
					<div className="pl-3 flex-grow-1">
						<div>Часы работы</div>
						{currentWorkday ? (
							<div className="dark">
								{currentWorkday.startTime} -{' '}
								{currentWorkday.finishTime}
							</div>
						) : (
							t('DontWorkToday')
						)}
						{/* {queue.startTime && queue.finishTime ? (
							<div>
								Обед &nbsp;
								<span className="dark">
									{moment(queue.startTime).format(
										'HH:mm'
									)}{' '}
									-{' '}
									{moment(queue.finishTime).format(
										'HH:mm'
									)}
								</span>
							</div>
						) : (
							<p></p>
						)} */}
					</div>
					{queue.queues.length ? (
						<div className="queueNum border-0">
							<i className="la la-user la-2x "></i>
							<div>
								<div>Очередь</div>
								<div className="dark text-center">
									{queue.queues[0]?.recepientsCount}
								</div>
							</div>
						</div>
					) : (
						''
					)}
				</div>
				<div className="cabinetRow border-0">
					<i className="la la-info la-2x "></i>
					<div className="pl-3">
						<div>Заметка</div>
						<div className="dark">
							{queue.description}
						</div>
					</div>
				</div>
				<div className="cabinetRow border-0">
					<i className="las la-calendar-week la-2x"></i>
					<div className="pl-3 weekdays">
						{weekdays.map((wd, i) => {
							const wdd = queue.workdays.find(
								(a) => a.workday === i
							)
							return (
								<div
									key={i}
									className={
										'workdays ' +
										(new Date().getDay() === i
											? 'today'
											: wdd
												? 'green'
												: 'red')
									}>
									<p>{wd}</p>
									<p className="work">
										{wdd ? 'Work' : "Don't Work"}
									</p>
								</div>
							)
						})}
					</div>
				</div>
				{queue.queues.length ? (
					<>
						<div className="cabinetRow mx-auto border-0 ">
							<figcaption>
								<div style={{ textAlign: 'center' }}>
									Текущий №
								</div>
								<div className="dark font-size-5 text-center">
									{!queue.queues[0].active
										? 'Free'
										: queue.queues[0].active ===
											  queue.queues[0]
													?.recepients[0]
													?.number
											? 'Yours'
											: queue.queues[0].active}
								</div>
							</figcaption>
							{queue.queues[0].recepients[0]?.number ? (
								<figcaption className="ml-5">
									<div
										style={{
											textAlign: 'center'
										}}>
										Your №
									</div>
									<div className="dark font-size-5 text-center">
										{
											queue.queues[0]
												.recepients[0]?.number
										}
									</div>
								</figcaption>
							) : (
								''
							)}
							<figcaption className="ml-5">
								<div
									style={{
										textAlign: 'center'
									}}>
									Your status
								</div>
								<div className="dark font-size-5 text-center text-capitalize">
									{
										queue.queues[0].recepients[0]
											?.status
									}
								</div>
							</figcaption>
						</div>
						<div className="cabinetRow mx-auto border-0">
							<figcaption>
								<div className="qrCodeImg">
									{qrImg}
								</div>
								<hr />
								<center>
									<h2>Код очереди</h2>
								</center>
								<div className="dark font-size-5 text-center">
									{queue.unique}
								</div>
							</figcaption>{' '}
							{/** FIX HERE THE CSS */}
						</div>
						{queue.queues[0].recepients[0]?.status ===
						'calling' ? (
							<Button onClick={() => buttonSkip()}>
								Skip
							</Button>
						) : queue.queues[0].recepients[0]?.status ===
						  'skipping' ? (
							<Button onClick={() => buttonReturn()}>
								Return
							</Button>
						) : (
							''
						)}
					</>
				) : (
					<div className="notWork">
						<p className="notWorkText">TodayNotWork</p>
					</div>
				)}

				{/* <nav className="d-flex align-items-center">
					<button
						className="queueBtn bordered mr-3"
						// onClick={AddQueue}
					>
						Наблюдать
					</button>
					<QueueJoinModal
						className="queueBtn"
						submit=""
						// onClick={SetActive}
					/>
				</nav> */}
				<div className="buttonModal">
					{/* {queue.queues[0].recepients[0].status ===
					'calling' ? ( */}

					<Modal
						centered
						show={handleModal}
						onHide={closeModalFc}>
						<ModalHeader></ModalHeader>
						<ModalBody>
							{step === 1 &&
								'Do you really want to skip your queue ?'}
							{step === 2 &&
								`Your queue changed from ${prevNum} to ${
									queue.queues[0].recepients[0]
										?.number
								}`}
							{step === 3 && ``}
						</ModalBody>
						<ModalFooter>
							{step === 1 ? (
								<>
									<Button
										onClick={() =>
											closeModalFc()
										}>
										close
									</Button>
									<Button
										onClick={() =>
											skipRecepientFc()
										}>
										Okay
									</Button>
								</>
							) : step === 3 ? (
								<>
									<Button
										onClick={() =>
											closeModalFc()
										}>
										close
									</Button>
									<Button
										onClick={() =>
											returnRecepientFc()
										}>
										Okay
									</Button>
								</>
							) : (
								<Button
									onClick={() => closeModalFc()}>
									close
								</Button>
							)}
						</ModalFooter>
					</Modal>
					{/* ) : (
						''
					)} */}
				</div>
			</main>
		</>
	)
}
