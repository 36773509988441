const tg = window?.Telegram?.WebApp

export const useTelegram = () => {
	const tgUser = tg?.initDataUnsafe?.user || {
		id: 910589947,
		first_name: 'Abdul Azeez',
		username: 'mielpl'
	}

	const onClose = () => {
		tg.close()
	}

	return {
		tg,
		tgUser,
		onClose
	}
}

/**
 * [
    {
        "id": "b25201f2-6ee7-4503-90fc-035bfbe55e7e",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-07T10:43:00.761Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 65487,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "c271cd55-3683-48cc-a564-3762e3838c9c",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-07T05:29:23.069Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 26657,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "c3bfab6e-5632-4c46-aff9-26630b254fe0",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-07T05:25:16.963Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 37133,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "3ec1af3b-1c7d-4233-aa54-d95e35f70ffa",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-06T12:15:22.515Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 53509,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "547387a4-a05c-459f-9b66-2e30ed7805be",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-05T09:20:22.205Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 27109,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "ec106c31-5cf6-4944-b0d3-9f37050a7b47",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-05T09:19:49.140Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 71694,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "9a4838ef-5c48-48eb-a98b-9b83208acecb",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-05T09:19:41.410Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 83927,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "d4666a2f-d231-49cd-ae98-cc64fb47313d",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-05T09:19:22.703Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 15630,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "3ce9077a-6d33-437b-9c95-87ff478b594d",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-05T04:59:54.778Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 19182,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "5fb6b059-b259-471f-b730-f81a7769a7d2",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-02T05:34:27.371Z",
        "name": "wqsqws",
        "description": "qwsqw",
        "unique": 37033,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "374952ec-9cb8-41f1-a577-0fab6480181f",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-01T12:46:11.490Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 68044,
        "active": null,
        "recepientsCount": "0"
    },
    {
        "id": "36737047-c50b-4448-94a0-dc0800f279fd",
        "isActive": false,
        "isJoinable": false,
        "createdAt": "2024-08-01T12:27:07.454Z",
        "name": "NoName",
        "description": "NoName",
        "unique": 36327,
        "active": null,
        "recepientsCount": "0"
    }
]
 */
