import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	OBSERVE_ROUTE,
	QUEUE_SCHEME_ROUTE,
	RESULT_ROUTE
} from '../util/routenames'
import { useTelegram } from './telegram'
import { useTranslation } from 'react-i18next'
import { queueSchemeStore } from '../store/queueSchemeStore'

const SearchQueue = () => {
	const { tg } = useTelegram()
	const { t } = useTranslation()
	const defaultFunc = (e) => e.preventDefault()
	const [searchValue, setSearchValue] = useState()
	const [foundValue, setFoundValue] = useState('')
	const [foundData, setFoundData] = useState(null)
	const navigate = useNavigate()

	const { checkQueueScheme } = queueSchemeStore()

	const searchQ = async (e) => {
		defaultFunc(e)
		if (!searchValue) {
			return
		}
		if (searchValue <= 0) {
			return toast.error(
				'Очередь не принимает номера меньше или равные нулю'
			)
		}
		try {
			const data =
				foundData ?? (await checkQueueScheme(searchValue))

			setSearchValue()
			if (
				!data ||
				data === 'null' ||
				data.message === 'NotFound'
			) {
				return toast.error(t('QueueSchemeNotFound'))
			} else if (data.message === 'InvalidUnique') {
				return toast.error(t('QueueSchemeInvalidUnique'))
			} else if (data.message === 'OwnQueue') {
				return navigate(QUEUE_SCHEME_ROUTE + '/' + data.id)
			} else if (data.message === 'Ok') {
				return navigate(OBSERVE_ROUTE + '/' + data.id)
			}
		} catch (e) {
			toast.error(e.response.data.message)
		}
	}

	const scanQR = () => {
		if (tg) {
			tg.showScanQrPopup(
				{ text: 'SCAN QR AND TEST' },
				(text) => {
					alert(text)
					if (
						text.match(
							/^https:\/\/navbat\.uz\/join\/\d+$/
						)
					) {
						const code = text
							.split(process.env.REACT_APP_SITE_URL)[1]
							.split('join/')[1]
						navigate(RESULT_ROUTE + '/' + code)
					} else {
						alert('TEXT IS NOT CORRECT')
					}
					tg.closeScanQrPopup()
				}
			)
		}
	}

	useEffect(() => {
		const delay = setTimeout(async () => {
			if (searchValue && searchValue > 0) {
				const data = await checkQueueScheme(searchValue)

				setFoundData(data)
				if (
					!data ||
					data === 'null' ||
					data.message === 'NotFound'
				) {
					return setFoundValue(t('QueueSchemeNotFound'))
				} else if (data.message === 'InvalidUnique') {
					return setFoundValue(
						t('QueueSchemeInvalidUnique')
					)
				} else if (
					data.message === 'OwnQueue' ||
					data.message === 'Ok'
				) {
					return setFoundValue(data.name)
				}
			}
		}, 1500)
		return () => clearTimeout(delay)
	}, [searchValue, checkQueueScheme, t])

	const onFocused = (e) => {
		e.target.classList.add('focused')
		const formSearch = e.target.closest('form')
		const svgIcon = formSearch.querySelector('label svg')
		const qrSeIcon = formSearch.querySelector('i.la-qrcode')
		qrSeIcon.classList.add('hideFocus')
		svgIcon.classList.add('focused')
		svgIcon.children[0].setAttribute(
			'fill',
			'url(#paint0_linear_15:841)'
		)
	}
	const onBlured = (e) => {
		e.target.classList.remove('focused')
		const formSearch = e.target.closest('form')
		const svgIcon = formSearch.querySelector('label svg')
		const qrSeIcon = formSearch.querySelector('i.la-qrcode')
		qrSeIcon.classList.remove('hideFocus')
		svgIcon.classList.remove('focused')
		svgIcon.children[0].setAttribute('fill', '#979791')
	}

	return (
		<>
			<form className="searchCont" onSubmit={searchQ}>
				<input
					type="number"
					placeholder={t('EnterCode')}
					autoComplete="off"
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					onFocus={onFocused}
					onBlur={onBlured}
				/>
				<label className="mb-0">
					<input type="submit" hidden />
					<svg
						width="28"
						height="24"
						viewBox="0 0 28 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M27.185 13.1723C28.2272 12.7383 28.2272 11.2618 27.185 10.8278L2.7346 0.479957C1.70648 0.0392392 0.652933 1.0494 1.04559 2.09681L4.74532 12L1.04559 21.9033C0.65293 22.9507 1.70648 23.9608 2.7346 23.5201L27.185 13.1723Z"
							fill="#979791"
							onClick={searchQ}
						/>
						<defs>
							<linearGradient
								id="paint0_linear_15:841"
								x1="25.7746"
								y1="21.3554"
								x2="9.13897"
								y2="-0.489421"
								gradientUnits="userSpaceOnUse">
								<stop stopColor="#C0E91C" />
								<stop
									offset="1"
									stopColor="#73CC2C"
								/>
							</linearGradient>
						</defs>
					</svg>
				</label>
				<div
					onClick={scanQR}
					hidden={
						!tg ||
						tg.platform === 'web' ||
						tg.platform === 'tdesktop' ||
						tg.platform === 'unknown'
					}>
					<i className="la la-qrcode la-2x"></i>
				</div>
			</form>
			{foundValue ? (
				<div
					className="searchCont hint"
					onClick={(e) => searchQ(e)}>
					{foundValue}
				</div>
			) : null}
		</>
	)
}

export default SearchQueue
