import moment from 'moment'
import React from 'react'
import { Modal } from 'react-bootstrap'
const QROnCreate = ({ ...props }) => {
	const defaultFunc = (e) => {
		e.preventDefault()
	}

	const ShareTG = () => {
		props.closeModal()
		window.open(
			'https://t.me/share/url?url=' +
				process.env.REACT_APP_SITE_URL +
				'result/' +
				props.queueId,
			'_blank',
			'location=yes,height=570,width=520,scrollbars=yes,status=yes',
			true
		)
	}

	const PrintQR = (queue) => {
		const html = `<html><head><script>function step1() {setTimeout('step2()', 10)}function step2() {window.print();window.close()}</script><style type="text/css" media="print">@page {size: auto;margin: 0;}</style><style>body {display: grid;grid-template-columns: 1fr 1fr;grid-template-rows: repeat(5, 70px) auto;margin: 0 1rem}section.just {	grid-column-start: span 2;}section.just.center {	align-self: center;    justify-self: center;}</style></head><body onload='step1()'><h2>${
			queue.name + ''
		}</h2><section class="just"><div>Макс. длина</div><span>${
			queue.maxsize
		}</span></section><section><div>Дата создания</div><span>${moment(
			queue.createdAt
		).format(
			'DD.MM.yyyy'
		)}</span></section><section><div>Дата окончания</div><span>${moment(
			queue.finishDate
		).format(
			'DD.MM.yyyy'
		)}</span></section><section><div>Часы работы</div><span>${moment(
			queue.startDate
		).format('HH:mm')} - ${moment(queue.finishDate).format(
			'HH:mm'
		)}</span></section><section>${
			queue.startTime && queue.finishTime ? (
				<div>
					<div>Обед</div>
					<p>
						${moment(queue.startTime).format('HH:mm')} - $
						{moment(queue.finishTime).format('HH:mm')}
					</p>
				</div>
			) : null
		}</section><section class="just"><div>Заметка</div><span>${
			queue.message
		}</span></section><section class="just center"><img width='350' src="${
			props.imgQr.props.src
		}"/></section></body></html>`

		function ImagetoPrint() {
			return html
		}
		var Pagelink = 'about:blank'
		var pwa = window.open(Pagelink, '_blank')
		pwa.document.open()
		pwa.document.write(ImagetoPrint())
		pwa.document.close()
		return
	}
	return (
		<>
			<props.nodeName
				className={props.attrs.className}
				{...props.attrs}
				onClick={() => props.onCreate()}>
				{props.text}
			</props.nodeName>
			<Modal
				show={props.show}
				onHide={props.closeModal}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<form
					className="modalForm text-center"
					onSubmit={defaultFunc}>
					<Modal.Header>
						<Modal.Title>
							Ваш QR-Код для клиентов
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>{props.imgQr}</Modal.Body>
					<Modal.Footer className="btnSubmit pb-2">
						<button
							submit=""
							className="queueBtn bordered"
							onClick={ShareTG}>
							Поделиться в телеграме
						</button>
						<button
							submit=""
							// onClick={() => history.push(QUEUE_ROUTE)}
						>
							Список очередей
						</button>
						<button
							submit=""
							onClick={() => PrintQR(props.queue)}>
							Распечатать
						</button>
					</Modal.Footer>
				</form>
			</Modal>
		</>
	)
}

export default QROnCreate
