import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { languageStore } from '../../store/languageStore'
import { Welcome } from '../../pages/Welcome'
import i18n from '../switchLanguage/i18n'
import { useTranslation } from 'react-i18next'

export function RegisterModal() {
	const { t } = useTranslation()
	const [show, setShow] = useState(true)
	const [step, setStep] = useState(1)

	const { language } = languageStore()

	const prevShoving = () => {
		if (step === 1) {
			return
		} else {
			setStep(step - 1)
		}
	}
	const nextShowing = (e) => {
		if (step === 3) {
			e.target.setAttribute('form', 'registerForm')
		} else {
			setStep(step + 1)
		}
		if (step === 1) {
			i18n.changeLanguage(e)
		}
	}

	return (
		<>
			<Modal show={show} backdrop="static" keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Navbat - Kutmang!</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{step === 1 && (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							{language.map((lang) => (
								<div
									key={lang.shortName}
									style={{
										margin: 20
									}}>
									<button
										onClick={() =>
											nextShowing(
												lang.shortName
											)
										}
										style={{
											background: 'transparent',
											border: 0,
											fontSize: 20
										}}>
										<img
											src={lang.flag}
											alt={lang.shortName}
											width={50}
											height={30}
										/>
										<Form.Check
											style={{
												appearance: 'none'
											}}
											label={lang.name}
											name="group1"
											type={'radio'}
										/>
									</button>
								</div>
							))}
						</div>
					)}
					{step === 2 && (
						<div>
							Lorem ipsum dolor sit amet consectetur
							adipisicing elit. Nulla eos asperiores quo
							minus dignissimos, corporis voluptas
							tempore sapiente dolor, illo unde? Unde
							iure itaque nostrum voluptate consectetur
							beatae facere mollitia. Alias quo eum est
							porro quisquam amet reprehenderit vitae
							praesentium et sequi nesciunt blanditiis
							aliquid deserunt, repellat eos laborum
							facere libero similique optio hic quas,
							delectus modi animi harum. Esse! Quas ab
							ullam dolor nemo quia debitis, cupiditate
							velit omnis nesciunt natus fuga deleniti
							officia sequi perspiciatis tempore quo
							numquam odio quae maiores, consequuntur
							distinctio porro. Praesentium iusto
							laboriosam id! Laborum consequuntur fugiat
							placeat harum vel totam doloribus officiis
							ratione, amet delectus nobis. Praesentium
							at, iure eius, nesciunt, repudiandae nulla
							veritatis error quae eligendi quasi
							assumenda odit deserunt. Odio, natus!
							Repellat enim sapiente sed itaque
							quibusdam laboriosam doloremque! Mollitia
							modi blanditiis sapiente iusto libero
							minima aliquid impedit amet reprehenderit
						</div>
					)}
					{step === 3 && <Welcome setShow={setShow} />}
				</Modal.Body>
				<Modal.Footer>
					{step !== 1 ? (
						<>
							<Button
								variant="secondary"
								onClick={prevShoving}>
								{t('Back')}
							</Button>
							<Button
								onClick={nextShowing}
								variant="primary"
								type="submit">
								{step === 3 ? t('Submit') : t('Next')}
							</Button>
						</>
					) : (
						''
					)}
				</Modal.Footer>
			</Modal>
		</>
	)
}
