import { $authHost } from '.'

export const join = async (data) => {
	const { data: response } = await $authHost.post(
		'recepient/' + data.queueId + '/join',
		data
	)
	return response
}

export const getRecepients = async () => {
	const { data } = await $authHost.get('recepient/my')
	return data
}

export const getRecepientsSchemes = async () => {
	const { data } = await $authHost.get('recepient/my/queue-scheme')
	return data
}

export const getRecepientHistory = async (page) => {
	const { data } = await $authHost.get(
		'recepient/my/monitoring?page=' + page
	)
	return data
}

export const finishRecepient = async (
	queueSchemeId,
	queueId,
	recepientId
) => {
	const { data } = await $authHost.post(
		'recepient/finish/' +
			queueSchemeId +
			'/' +
			queueId +	
			'/' +
			recepientId
	)
	return data
}

export const skipRecepient = async (
	queueSchemeId,
	queueId,
	recepientId
) => {
	const { data } = await $authHost.post(
		'recepient/skip/' +
			queueSchemeId +
			'/' +
			queueId +
			'/' +
			recepientId
	)
	return data
}

export const returnRecepient = async (
	queueSchemeId,
	queueId,
	recepientId
) => {
	const { data } = await $authHost.post(
		'recepient/return/' +
			queueSchemeId +
			'/' +
			queueId +
			'/' +
			recepientId
	)
	return data
}
