import HistoryEmployed from '../components/HistoryEmployed'
import './css/History.css'
import { useTranslation } from 'react-i18next'

export const History = () => {
	const { t } = useTranslation()
	return (
		<>
			<header>
				<h2 className="mainTopTitle">{t('Monitoring')}</h2>
			</header>
			<main className="his">
				<HistoryEmployed />
			</main>
		</>
	)
}
