import React from 'react'
import logo from '../img/NavbatLogoSmall.svg'
import mainText from '../img/NavbatText.svg'
import subText from '../img/KutmangText.svg'

export const Start = () => {
	const style = {
		main: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			height: window.innerHeight + 'px',
			width: '100%',
			background: '#fffff5',
			overflow: 'hidden',
			position: 'relative',
			zIndex: '1'
		},
		logoImg: { width: '35vw', maxWidth: '200px' },
		logoMainText: { margin: '15px 0 5px' },
		logoSubText: {}
	}

	return (
		<>
			<link rel="preconnect" href="https://queue.uz"></link>
			<main style={style.main}>
				<img src={logo} alt="logo" style={style.logoImg} />
				<img
					src={mainText}
					alt="mainText"
					style={style.logoMainText}
				/>
				<img
					src={subText}
					alt="subText"
					style={style.logoSubText}
				/>
			</main>
			<style>{`#navbat-root{margin-bottom:0}`}</style>
		</>
	)
}

export default Start
