import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { recepientStore } from '../store/recepientStore'
import {
	FrownOutlined,
	MehOutlined,
	SmileOutlined
} from '@ant-design/icons'
import { Flex, Rate } from 'antd'
import '../pages/css/DoneModal.scss'

export const DoneModal = () => {
	const [doneModal, setDoneModal] = useState(false)

	const { recepients } = recepientStore()

	useEffect(() => {
		const recepient = recepients.find(
			(q) => q.queues[0].recepients[0].status === 'done'
		)

		if (recepient) {
			setDoneModal(true)
		}
	}, [recepients])

	const customIcons = {
		1: <FrownOutlined />,
		2: <FrownOutlined />,
		3: <MehOutlined />,
		4: <SmileOutlined />,
		5: <SmileOutlined />
	}

	return (
		<Modal show={doneModal} centered>
			<ModalBody className="doneModalBody">
				<div className="doneModalText">
					<p>Your queue has been done !</p>
					<p>Please rate our service </p>
				</div>

				<Flex gap="middle" vertical>
					<Rate
						className="reviewRate"
						defaultValue={0}
						character={({ index = 0 }) =>
							customIcons[index + 1]
						}
					/>
				</Flex>

				<textarea placeholder="Leave Your Comments...." />
			</ModalBody>
			<ModalFooter>
				<button submit="" onClick={() => setDoneModal(false)}>
					Submit
				</button>
			</ModalFooter>
		</Modal>
	)
}
